<style lang="less" scoped>
#admin-detail {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .admin-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 10px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 350px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 200px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 30px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 15px;
      }
      .tips-list {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        display: inline-block;
        margin-left: 20px;
        width: 65%;
      }
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 50px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="admin-detail" id="admin-detail">
    <div class="tab-top">
      <table-title />
    </div>
    <div class="tab-top-line"></div>
    <div class="admin-info">
      <template>
        <a-form-model ref="infoForm" :model="form">
          <a-form-model-item label="名称" prop="admin_name" ref="admin_name">
            <a-input v-model="form.admin_name" placeholder="请输入名称"/>
          </a-form-model-item>
          <a-form-model-item label="账号" prop="admin_account" ref="admin_account">
            <a-input v-model="form.admin_account" placeholder="请输入账号"/>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email" ref="email">
            <a-input v-model="form.email" placeholder="请输入邮箱"/>
          </a-form-model-item>
          <a-form-model-item label="角色" prop="role_id" ref="role_id">
            <a-select v-model="form.role_id" placeholder="请选择角色">
              <a-select-option :key="1">超级管理员</a-select-option>
              <a-select-option :key="2">普通管理员</a-select-option>
              <a-select-option :key="3">销售人员</a-select-option>
              <a-select-option :key="4">编程人员</a-select-option>
              <a-select-option :key="5">项目经理</a-select-option>
              <a-select-option :key="6">研究人员</a-select-option>
              <a-select-option :key="7">DP</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </template>
      <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import { fetchAdminCreate, fetchAdminInfo, updateAdmin } from "@/api/admin"
export default {
  name: "AdminDetail",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
  },
  data() {
    return {
      loading: false,
      adminId: undefined,
      form: {
        admin_name: undefined,
        admin_account: undefined,
        email: undefined,
        role_id: undefined,
      },
    };
  },
  created() {
    this.adminId = this.$route.query.adminId;
    this.initData();
  },
  methods: {
    initData() {
      if (this.adminId != undefined) {
        this.getAdminDetail()
      }
    },
    /// 获取用户信息
    async getAdminDetail() {
      try {
        let res = await fetchAdminInfo({
          info_admin_id: this.adminId
        });
        this.form.admin_account = res.admin_account;
        this.form.admin_name = res.admin_name;
        this.form.email = res.email;
        this.form.role_id = res.role_id;
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      if (!this.form.admin_name) {
        this.$message.error("请输入名称");
        return
      }
      if (!this.form.admin_account) {
        this.$message.error("请输入账号");
        return
      }
      if (!this.form.email) {
        this.$message.error("请输入邮箱");
        return
      }
      if (!this.form.role_id) {
        this.$message.error("请选择角色");
        return
      }
      this.createAdminData();
    },
    async createAdminData() {
      this.loading = true;
      try {
        let params = {
          admin_account: this.form.admin_account,
          admin_name: this.form.admin_name,
          email: this.form.email,
          role_id: this.form.role_id,
        };
        if (this.adminId == undefined) {
          await fetchAdminCreate(params);
          this.$message.success("添加成功");
        }else {
          params["id"] = this.adminId;
          await updateAdmin(params);
          this.$message.success("更新成功");
        }
        this.loading = false;
        const that = this;
        setTimeout(() => {
          if (that.noticeId == undefined) {
            that.$router.push({ name: "admin"});
          }else {
            that.$router.go(-1);
          }
        }, 500);
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>